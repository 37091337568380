import React from 'react';
import SEO from "../../components/seo/Seo";
import Header from "../../components/headers/Header";
import AnimatedExplorationsDescriptions from "../../components/animated-explorations/AnimatedExplorationsDescriptions";
import Footer from "../../components/layouts/Footer";
import CallToAction from "../../components/layouts/CallToAction";
import AnimatedHeader from "../../components/animated-explorations/AnimatedHeader";

function AnimiranaVodenja({data}) {
    return (
        <div>
            <SEO title="Animirana vodenja" description="Animirana vodenja raziskovanje podzemnega sveta naredijo prav posebno doživetje. Vodenja kot so S škratom po Rudniku Sitarjevec, sitarjevška maalca in Škratov šmorn, Pravljice in sitarjevške zgodbe, Litko in zgodba o Litku pričarajo litijsko podzemlje na poseben način." />
            <Header/>
            <AnimatedHeader/>
            <AnimatedExplorationsDescriptions/>
            <CallToAction/>
            <Footer/>
        </div>
    );
}

export default AnimiranaVodenja;