import React from 'react';
import { FiCheck } from "react-icons/fi";
import { Link } from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";

function AnimatedGoodToKnow(props) {
    return (
        <div className="rn-about-area ptb--120 bg_color--3 text-center text-lg-left">
            <div className="container">
                <div className="row row--35 align-items-center">
                    <div className="col-lg-6 order-lg-1 order-0">
                        <div className="">
                            <div className="section-title">
                                <h2 className="title">Dobro je vedeti</h2>
                            </div>
                            <div className="mt--30">
                                <ul className="list-style--1">
                                    <li>
                                        <FiCheck />
                                        Ogled je možen samo s predhodno najavo - <Link to="/kontakt-in-informacije">rezervacija.</Link>
                                    </li>

                                    <li>
                                        <FiCheck />
                                        Obiskovalec prejme zaščitno opremo – čelado, priporočamo udobno obutev in obleko.
                                    </li>

                                    <li>
                                        <FiCheck />
                                        Ogled rudnika traja do ene ure.
                                    </li>
                                    <li>
                                        <FiCheck />
                                        V rovu je stalna temperatura 10°C in 100% vlaga.
                                    </li>
                                </ul>
                            </div>

                            <div className="mt--40">
                                <Link to="/kontakt-in-informacije" className="btn-default size-md">Rezerviraj ogled</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 mt-5">
                        <div className="thumbnail">
                            <StaticImage
                                src="../../assets/images/rudnik/s-skratom-po-rudniku.jpeg"
                                alt="S skratom po rudniku"
                                placeholder="blurred"
                                imgClassName="img-fluid w-100"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default AnimatedGoodToKnow;